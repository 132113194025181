<template>
  <div class="id-card p-30">
    <back></back>
    <div class="f-17 text-center m-b-20 bold">【轻松小镇会员等级体系说明】</div>
    <div class="m-b-5 bold">【普卡】</div>
    <div class="m-b-20">1、说明：刚加入轻松小镇，对一切感觉新奇又陌生的新居民
      <br>
      2、获得获得条件：用户免费注册成为轻松小镇居民后自动获得
      <br>
      3、资格有效期及保级条件：永久，不需保级
      <br>
      4、享受权益：<br> 全年高铁/机场VIP休息服务3次</div>
    <div class="m-b-5 bold">【银卡】</div>
    <div class="m-b-20">1、说明：开始参与轻松小镇的日常生活，逐渐融入小镇的居民
      <br>
      2、获得条件：全年累计获得超过500元石，并在轻松小镇中绑定按摩器
      <br>
      3、资格有效期及保级条件：1年，下一年累计获得超过500元石，即可保级
      <br>
      4、享受权益：<br>
      直营门店全县产品9.8折优惠<br>
      全年高铁/机场VIP休息服务10次<br>
      亚朵/华住8折优惠<br>
      神州专车/滴滴专车8折卡/全年1次</div>
    <div class="m-b-5 bold">【金卡】</div>
    <div class="m-b-20">1、说明：在轻松小镇中扮演着重要角色，与小镇共同成长
      <br>
      2、获得资格：全年累计获得超过3000枚元石，且当年在轻松小镇中绑定按摩器并累计获得超过3000缕元气
      <br>
      3、资格有效期与保级条件：1年，每年累计获得超过3000枚元石与3000缕元气，即可保级
      <br>
      4、享受权益：<br>
      直营门店全县产品9.5折优惠<br>
      全年高铁/机场VIP休息服务20次<br>
      倍轻松产品专属定制名额1个<br>
      可倍轻松新品发布会VIP资格抽签（包往返机票）<br>
      亚朵/华住/木九十8折优惠<br>
      神州专车/滴滴专车8折卡/全年2次</div>
    <div class="m-b-5 bold">【黑卡】</div>
    <div class="m-b-20">1、说明：轻松小镇核心贡献者，可获得珍贵的NFT数字藏品，黑卡获得者可享受轻松小镇最高级的礼遇
      <br>
      2、获得资格：<br>
      （1）连续3年金卡保级成功，且累计获得元气超过20000缕满足基础条件<br>
      （2）参与轻松小镇用户共治社区，社区每年会向满足基础条件且为社区做出突出贡献的用户发放黑卡邀请
      <br>
      3、资格有效期与保级条件：1年，每年需要参与年度贡献考核，通过考核即可保级
      <br>
      4、享受权益：<br>
      直营门店全县产品9折优惠<br>
      高铁/机场VIP休息服务不限次卡<br>
      全年2次双人轻松之旅（往返机票+酒店3天）<br>
      倍轻松产品专属定制名额3个<br>
      倍轻松新产品规划官资格<br>
      可倍轻松新品发布会VIP双人资格（包往返机票）<br>
      亚朵/华住/木九十8折优惠<br>
      神州专车/滴滴专车8折卡/全年3次</div>
  </div>
</template>

<script>
import Back from '../components/Back'
export default {
  name: 'IDCard',
  components: { Back },
  data () {
    return {
      isShow: false
    }
  }
}
</script>

<style scoped lang="scss">
.id-card{
  background: url("../assets/img/rules-bg.png") no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding-top: 50px;
}
</style>
